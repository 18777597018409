




import { Component, Vue, Prop } from "vue-property-decorator";
import Loader from "@/components/UI/Loader.vue";
import { ScriptReceiver } from "@/services/DOM/ScriptReceiver";
import { IS_DEV } from "@/constants/buildMode";
import { app_routes } from "@/router/app_routes";
import { CertificateType } from "types/IIT/Widget/CertificateType";

const scriptReceiver = new ScriptReceiver();

@Component({
  components: { Loader },
})
export default class EdsComponent extends Vue {
  @Prop({ type: Function, required: true }) cbAfterAuth!: (
    certs: CertificateType[]
  ) => Promise<void>;

  async mounted(): Promise<void> {
    try {
      const edsUrl = process.env.VUE_APP_EDS_URL || "";

      if (edsUrl) {
        await scriptReceiver.insert(edsUrl); // load eds library
        await window.eds.init({
          debug: IS_DEV,
          callbackAfterAuth: async (certs: CertificateType[]) => {
            await this.cbAfterAuth(certs);
          },
        }); // init EDS
        await window.eds.loadWidget(); // init widget
      } else {
        await this.$router.push(app_routes.server_error.path);
      }
    } catch {
      await this.$router.push(app_routes.server_error.path);
    }
  }
}
