export interface IScriptReceiver {
  insert(src: string, isAsync?: boolean, isDefer?: boolean): Promise<void>;
}

export class ScriptReceiver implements IScriptReceiver {
  private readonly SCRIPT_TAG = "script";
  private readonly SCRIPT_TYPE = "text/javascript";

  insert(src: string, isAsync?: boolean, isDefer?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      const script: HTMLScriptElement = document.createElement(this.SCRIPT_TAG);
      document.body.appendChild(script);

      script.src = src;
      script.type = this.SCRIPT_TYPE;
      script.async = Boolean(isAsync);
      script.defer = Boolean(isDefer);

      (script as any).onload = resolve;
      (script as any).onerror = reject;
    });
  }
}
