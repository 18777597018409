














import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import EdsComponent from "@/components/Eds/EdsComponent.vue";
import { USER_MANAGER_MODULE } from "@/constants/storeNames";
import { CertificateType } from "types/IIT/Widget/CertificateType";
import { UserInfoType } from "@/types/API/UserInfoType";

const userManager = namespace(USER_MANAGER_MODULE);

@Component({
  components: { EdsComponent },
})
export default class Login extends Vue {
  @userManager.State userInfo!: UserInfoType;
  @userManager.Getter getHomeRoute!: string;

  @userManager.Mutation setUserName!: (name: string) => void;
  @userManager.Action getAuthToken!: () => Promise<string>;
  @userManager.Action login!: (sign: string) => Promise<void>;

  async cbAfterAuth(certs: CertificateType[]): Promise<void> {
    const token = await this.getAuthToken(); // receive data for the signature

    const sign = await window.eds.sign(token, {
      asBase64String: true,
    });

    await this.login(sign);

    const cert: CertificateType | undefined = certs.pop();
    if (cert !== undefined) {
      this.setUserName(cert.infoEx.subjFullName || "");
    }

    await this.$router.push({
      path: this.getHomeRoute,
    });
  }
}
